var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-card-title',[_c('h2',{staticClass:"mr-5"},[_vm._v("Credit Notes")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.new_credit_note_dialog = true}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" New Credit Note")],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('v-data-table',{attrs:{"items":_vm.filteredCreditNotes,"headers":_vm.credit_note_headers,"dense":"","sort-by":['credit_note_number', 'credit_note_date'],"items-per-page":1000,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"lg":"3","md":"3","sm":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","placeholder":"Search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"lg":"2","md":"2","sm":"2"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","append-icon":"mdi-filter-variant","label":"Type","items":[
                        {
                          name: 'Down Payment Refunds',
                          value: 'sales_order',
                        },
                        { name: 'Credit Notes', value: 'invoice' } ],"item-text":"name","item-value":"value","clearable":""},on:{"click:clear":function($event){_vm.type = null}},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),_c('v-col',{attrs:{"lg":"2","md":"2","sm":"2"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","append-icon":"mdi-filter-variant","label":"Refunded State","items":[
                        { name: 'Refund Paid' },
                        { name: 'Refund Owed' } ],"item-text":"name","item-value":"name","clearable":""},on:{"click:clear":function($event){_vm.refund_state = null}},model:{value:(_vm.refund_state),callback:function ($$v) {_vm.refund_state=$$v},expression:"refund_state"}})],1)],1)],1)]},proxy:true},{key:"item.customer_name",fn:function(props){return [_vm._v(" "+_vm._s(props.item.customer_name)+" ")]}},{key:"item.reference_number",fn:function(props){return [_vm._v(" "+_vm._s(props.item.reference_number)+" ")]}},{key:"item.total_credited_amount",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", props.item.total_credited_amount))+" ")]}},{key:"item.credit_note_amount_refunded",fn:function(props){return [_vm._v(" "+_vm._s(_vm.formatAsCurrency("R", props.item.credit_note_amount_refunded))+" ")]}}])})],1)],1)],1)],1),_c('NewCreditNoteModal',{attrs:{"dialog":_vm.new_credit_note_dialog},on:{"closeDialog":function($event){_vm.new_credit_note_dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }