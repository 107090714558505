<template>
  <div>
    <v-container fluid class="px-10">
      <v-row class="mb-8">
        <v-col lg="12">
          <v-card-title>
            <h2 class="mr-5">Credit Notes</h2>
            <!--Add Button-->
            <v-btn color="primary" @click="new_credit_note_dialog = true"
              ><v-icon small left>mdi-plus</v-icon> New Credit Note</v-btn
            >
          </v-card-title>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet elevation="4">
            <v-data-table
              :items="filteredCreditNotes"
              :headers="credit_note_headers"
              dense
              :sort-by="['credit_note_number', 'credit_note_date']"
              :items-per-page="1000"
              :search="search"
            >
              <!--Filters-->
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <!--Search -->
                    <v-col lg="3" md="3" sm="3">
                      <v-text-field
                        v-model="search"
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        placeholder="Search..."
                      ></v-text-field>
                    </v-col>
                    <!--Downpayments // Invoices-->
                    <v-col lg="2" md="2" sm="2">
                      <v-autocomplete
                        v-model="type"
                        outlined
                        dense
                        append-icon="mdi-filter-variant"
                        label="Type"
                        :items="[
                          {
                            name: 'Down Payment Refunds',
                            value: 'sales_order',
                          },
                          { name: 'Credit Notes', value: 'invoice' },
                        ]"
                        item-text="name"
                        item-value="value"
                        clearable
                        @click:clear="type = null"
                      ></v-autocomplete>
                    </v-col>
                    <!--Refunded State Filter-->
                    <v-col lg="2" md="2" sm="2">
                      <v-autocomplete
                        v-model="refund_state"
                        outlined
                        dense
                        append-icon="mdi-filter-variant"
                        label="Refunded State"
                        :items="[
                          { name: 'Refund Paid' },
                          { name: 'Refund Owed' },
                        ]"
                        item-text="name"
                        item-value="name"
                        clearable
                        @click:clear="refund_state = null"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <!--Format customer-->
              <template v-slot:item.customer_name="props">
                {{ props.item.customer_name }}
              </template>
              <!--Format invoice-->
              <template v-slot:item.reference_number="props">
                {{ props.item.reference_number }}
              </template>
              <!--Format amounts-->
              <template v-slot:item.total_credited_amount="props">
                {{ formatAsCurrency("R", props.item.total_credited_amount) }}
              </template>
              <template v-slot:item.credit_note_amount_refunded="props">
                {{
                  formatAsCurrency("R", props.item.credit_note_amount_refunded)
                }}
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <NewCreditNoteModal
      :dialog="new_credit_note_dialog"
      @closeDialog="new_credit_note_dialog = false"
    />
  </div>
</template>
<script>
import db from "../../../../components/firebaseInit";
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "CreditNoteTable",
  components: {
    NewCreditNoteModal: () => import("../Modals/NewCreditNoteModal.vue"),
  },
  data() {
    return {
      new_credit_note_dialog: false,
      credit_notes: [],
      monthDocs: [],
      search: "",
      type: null,
      refund_state: null,
      credit_note_headers: [
        { text: "Credit Note #", value: "credit_note_number", align: "start" },
        { text: "Customer", value: "customer_name" },
        { text: "Reference", value: "reference_number" },
        { text: "Date Issued", value: "credit_note_date" },
        { text: "Credited Amount", value: "total_credited_amount" },
        { text: "Amount Refunded", value: "credit_note_amount_refunded" },
      ],
    };
  },
  computed: {
    filteredCreditNotes() {
      const filters = {
        typeFilter: (item) => this.type in item,
        stateFilter: (item) => item.refunded === this.refund_state,
      };
      const selected = [];
      //  Set conditions to apply filters to the array
      this.type ? selected.push(filters.typeFilter) : null;
      this.refund_state ? selected.push(filters.stateFilter) : null;
      const output = this.credit_notes.filter((item) =>
        selected.every((filter) => filter(item))
      );
      return output;
    },
  },
  created() {
    this.getCreditNotes();
  },
  methods: {
    formatAsCurrency,
    //
    getCreditNotes() {
      db.collection("customer_credit_notes").onSnapshot((snapshot) => {
        this.credit_notes = snapshot.docs.map((doc) => ({
          ...doc.data(),
          customer_name: doc.data().customer.customer_name,
          reference_number: doc.data().invoice
            ? doc.data().invoice.invoice_number
            : doc.data().sales_order.order_number,
          refunded:
            doc.data().credit_note_amount_refunded ===
            doc.data().total_credited_amount
              ? "Refund Paid"
              : "Refund Owed",
        }));
      });
    },
  },
};
</script>